import * as React from 'react';
import I18nLink from '../i18n/Link';
import { FormattedMessage } from 'react-intl';
import { withIntl } from '../i18n';
import './404.scss';

const NotFoundPage = () => (
  <div className="mdc-layout-grid cm-404">
    <div className="mdc-layout-grid__inner">
      <div className="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--align-top">
        <I18nLink to="/" id="cm-logo">
          <span />
        </I18nLink>
        <h1 className="mdc-typography--headline1">
          <FormattedMessage id="error.404" defaultMessage="Hoppla, falscher Weg" />
        </h1>
        <p className="mdc-typography--subtitle1">
          <FormattedMessage id="error.404_subtitle" defaultMessage="Sieht so aus, als wäre die Seite oder Datei, die Sie wollten, verloren gegangen." />
        </p>
        <br />
        <I18nLink to="/" className="mdc-button mdc-button--raised" id="cm-back-cta">
          <FormattedMessage id="error.404_cta" defaultMessage="Zur Homepage" />
        </I18nLink>
      </div>
      <div className="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--align-top">
        <div id="cm-tractor-flat-tire-image" />
      </div>
    </div>
  </div>
)

export default withIntl(NotFoundPage)
